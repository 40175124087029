import "./grid.scss";
import classNames from "classnames";
import { useEffect, useState } from "react"




const Grid = ({ children, style }) => {
    return (
        <div style={style || {}} className="grid-content">
            {children}
        </div >
    );
};

const GridRow = ({ id, width, children }) => {
    return (
        <div key={id} className={classNames('grid-td', `grid-td-${width}`)}>
            {children}
        </div>
    );
};

export { Grid, GridRow };
